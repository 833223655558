import {
  captureException as sentryCaptureException,
  captureMessage as sentryCaptureMessage,
} from '@sentry/react';

interface LoggingArguments {
  message?: string;
  data?: Record<string, any>;
  context: string;
}

export class LoggerService {
  static error(err: any, ignoreReporting = false) {
    console.error(err);

    if (ignoreReporting) {
      return;
    }

    if (typeof err === 'string') {
      sentryCaptureMessage(err, 'error');
      return;
    }
    sentryCaptureException(err);
  }

  static info(args: LoggingArguments) {
    console.info(...LoggerService.buildLoggingArguments('INFO', args));
  }

  private static buildLoggingArguments(
    level: string,
    { message, data, context }: LoggingArguments
  ) {
    return [
      LoggerService.buildLoggedMessage(context, level, message),
      data,
    ].filter(Boolean);
  }

  private static buildLoggedMessage(
    context: string,
    level: string,
    message?: string
  ) {
    return `[${level}] ${context}${message ? ' - ' + message : ''}`;
  }
}
