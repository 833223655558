import { createAsyncThunk } from '@reduxjs/toolkit';

import { getHearingById, hearingsSelector } from './callHearingSelectors';
import { setReceivedPrimaryHearing } from './callHearingSlice';
import { adaptIncomingPrimaryHearing } from './callHearingAdapter';

import type { RootState } from 'features/app/store/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { isTeamingInProgressSelector } from 'features/teaming/teaming-base/store';
import { VoiceMeetingEventBus } from 'features/voice-meeting/services';
import type { VoiceSessionParticipantEvent } from 'features/voice-session/interfaces';
import { isHearingToDeafSelector } from 'features/call/call-base/store/selectors';
import { handleError } from 'features/notification/store';
import type { Call } from 'features/call/call-base/interfaces';

export const handleHearingDeclinedCall = createAsyncThunk(
  'call-hearing/disconnectedFromCall',
  async (payload: VoiceSessionParticipantEvent, { getState }) => {
    const state = getState() as RootState;
    const hearing = getHearingById(state, payload.participantId);
    const isTeaming = isTeamingInProgressSelector(state);

    if (hearing?.status === 'connecting') {
      VoiceMeetingEventBus.hearingCallDeclined$.next({
        phoneNumber: hearing.phoneNumber,
        disconnectReason: payload.disconnectedReason,
      });
    }

    if (hearing?.status === 'connected') {
      const hearings = hearingsSelector(state);
      const isSomeHearingConnected = hearings.some(
        (participant) =>
          participant.status === 'connected' && participant.id !== hearing.id
      );

      if (!isSomeHearingConnected && !isTeaming) {
        VoiceMeetingEventBus.finishVoiceSession$.next();
      }

      ParticipantsStatusEventBus.hearing.$disconnected.next({
        phoneNumber: hearing.phoneNumber,
        disconnectReason: payload.disconnectedReason,
        id: payload.participantId,
      });
    }
  }
);

export const primaryHearingReceived = createAsyncThunk(
  'callHearing/primaryHearingReceived',
  async (call: Call, { dispatch, getState }) => {
    const state = getState() as RootState;
    const isHearingToDeaf = isHearingToDeafSelector(state);
    try {
      const hearingToAdd = adaptIncomingPrimaryHearing(call, isHearingToDeaf);
      dispatch(setReceivedPrimaryHearing(hearingToAdd));
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'primaryHearingReceived',
          title: 'Failed to update primary hearing',
        })
      );
      throw error;
    }
  }
);
