import { type FC, type PropsWithChildren, Suspense, useEffect } from 'react';

import { useFetchAppConfig } from 'features/app-config/hooks';
import { finishAppLoading } from 'features/app-loading/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { AuthContext } from 'features/auth/contexts';
import { useAuthorization } from 'features/auth/hooks';
import { ModeStorageService } from 'features/multi-mode/services';
import { fetchCurrentUser, userSelector } from 'features/user/store';

export const TokenProvider: FC<PropsWithChildren> = ({ children }) => {
  const dispatch = useAppDispatch();
  const token = useAuthorization();
  const { fetchConfig } = useFetchAppConfig();
  const user = useAppSelector(userSelector);

  const handleTokenReceived = async () => {
    if (user.userName) {
      // User is already logged in, no need to fetch user data or configs
      return;
    }

    await dispatch(fetchCurrentUser()).unwrap();
    await fetchConfig();
    ModeStorageService.clear();
    dispatch(finishAppLoading());
  };

  useEffect(() => {
    if (!token) {
      return;
    }

    handleTokenReceived();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <Suspense>
      <AuthContext.Provider value={token}>{children}</AuthContext.Provider>
    </Suspense>
  );
};
