import { useCallback } from 'react';

import { VrsCallType } from 'features/call/vrs-call/enums';
import {
  selectIsSpawningEmergencyCall,
  spawnEmergencyCall,
  turnOffSpawningEmergencyCall,
  turnOnSpawningEmergencyCall,
} from 'features/emergency/store';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { terminationTypeSelector } from 'features/call/call-base/store/selectors';
import { CallNotificationType } from 'features/call/call-events/enums';
import { selectPrimaryAndSecondaryConnectedHearings } from 'features/call/call-hearing/store';
import { sendRnsNotification } from 'features/rns/store';
import { useSpawnNewCall } from 'features/spawn-call/hooks';
import {
  isTeamingStartedSelector,
  teamingAcceptingUserIdSelector,
  removeTeam,
  forceAcceptTeaming,
} from 'features/teaming/teaming-base/store';
import { useHearingDisconnect } from 'features/voice-meeting/hooks';
import { handleError } from 'features/notification/store';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

export const useEmergencySpawnNewCall = () => {
  const dispatch = useAppDispatch();
  const { disconnectMultipleHearings } = useHearingDisconnect();
  const { spawnCallTerminationType: handleTermSpawnCall } = useSpawnNewCall();
  const primaryAndSecondaryConnectedHearings = useAppSelector(
    selectPrimaryAndSecondaryConnectedHearings
  );
  const terminationType = useAppSelector(terminationTypeSelector);
  const isTeamingStarted = useAppSelector(isTeamingStartedSelector);
  const acceptingUserId = useAppSelector(teamingAcceptingUserIdSelector);
  const isSpawningEmergencyCall = useAppSelector(selectIsSpawningEmergencyCall);

  const spawnNewCallEmergency = useCallback(async () => {
    dispatch(
      sendAnalyticsInfo({
        Method: 'spawnNewCallEmergency',
        Message: 'Emergency call spawning started',
      })
    );
    if (isSpawningEmergencyCall) {
      dispatch(
        handleError({
          error: new Error('Emergency call spawning already in progress'),
          methodName: 'spawnNewCallEmergency',
        })
      );
      return;
    }

    try {
      dispatch(turnOnSpawningEmergencyCall());

      if (isTeamingStarted) {
        await dispatch(
          removeTeam({
            suppressTeamEndNotify: true,
            userId: acceptingUserId,
          })
        ).unwrap();
      }
      const terminationReason =
        (await handleTermSpawnCall()) ?? terminationType;

      if (primaryAndSecondaryConnectedHearings.length) {
        await disconnectMultipleHearings(
          primaryAndSecondaryConnectedHearings.map((hearing) => hearing.id)
        );
      }

      const newCallId = await dispatch(
        spawnEmergencyCall(terminationReason)
      ).unwrap();

      if (isTeamingStarted) {
        await dispatch(forceAcceptTeaming()).unwrap();
      }

      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.SPAWN_CALL,
          data: {
            callId: newCallId,
          },
        })
      ).unwrap();

      await dispatch(
        sendRnsNotification({
          eventName: CallNotificationType.SYNC_CALL_TYPE,
          data: {
            callType: VrsCallType.EMERGENCY_CALL,
          },
        })
      ).unwrap();
    } catch (error) {
      dispatch(turnOffSpawningEmergencyCall());
      dispatch(handleError({ error, methodName: 'spawnNewCallEmergency' }));
      throw error;
    }
  }, [
    isSpawningEmergencyCall,
    dispatch,
    isTeamingStarted,
    handleTermSpawnCall,
    terminationType,
    primaryAndSecondaryConnectedHearings,
    acceptingUserId,
    disconnectMultipleHearings,
  ]);

  return {
    spawnNewCallEmergency,
  };
};
