import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import type { DialPadValue } from 'features/dial-pad/interfaces';
import { DialPadDaoService, DialPadEventBus } from 'features/dial-pad/services';
import { handleError } from 'features/notification/store';
import { VrsChatEventBus } from 'features/vrs-chat/services';
import { messageToClearVPText } from 'features/vrs-chat/components';
import { SipSessionEventBus } from 'features/sip/services';
import { LogLevel } from 'features/analytics/enums';
import { sendAnalyticsInfo } from 'features/analytics/helpers';

// outbound thunk we want to send to both hearing and deaf participants as well as the dialpad state
export const sendDtmfThunkOutbound = createAsyncThunk(
  'dialPad/sendDtmfOutbound',
  async (
    {
      value,
      participantId,
      sessionId,
      isDeafConnected,
    }: {
      value: DialPadValue;
      participantId?: string;
      sessionId: string;
      isDeafConnected: boolean;
    },
    { dispatch }
  ) => {
    const stringValue = String(value);
    try {
      if (isDeafConnected) {
        DialPadEventBus.sendDtmf$.next(stringValue);
      }
      if (participantId) {
        await DialPadDaoService.sendDtmf({
          digit: stringValue,
          hearingParticipantId: participantId,
          sessionId,
        });
      }
      dispatch(
        sendAnalyticsInfo({
          Method: 'handleSendDtmf sent from VI',
          Level: LogLevel.INFO,
          Message: 'DTMF Sent',
        })
      );
    } catch (error) {
      dispatch(
        handleError({
          error,
          title: t('dtmfDigitNotSent'),
          methodName: 'sendDtmf',
        })
      );
      throw error;
    }
  }
);
// inbound thunk we only want to send to hearing participant
export const sendDtmfThunkInbound = createAsyncThunk(
  'dialPad/sendDtmfInbound',
  async (
    {
      value,
      participantId,
      sessionId,
    }: {
      value: DialPadValue;
      participantId?: string;
      sessionId: string;
    },
    { dispatch }
  ) => {
    try {
      if (participantId) {
        await DialPadDaoService.sendDtmf({
          digit: String(value),
          hearingParticipantId: participantId,
          sessionId,
        });
        dispatch(
          sendAnalyticsInfo({
            Method: 'handleSendDtmf sent from inbound participant',
            Level: LogLevel.INFO,
            Message: 'DTMF Sent',
          })
        );
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          title: t('dtmfDigitNotSent'),
          methodName: 'sendDtmf',
        })
      );
      throw error;
    }
  }
);

export const clearSharedTextThunk = createAsyncThunk(
  'dialPad/clearDialPadAndClearSharedText',
  async (dialedNumber: string) => {
    const encoder = new TextEncoder();
    const backspace = encoder.encode('\b');

    for (const _ of dialedNumber) {
      VrsChatEventBus.$sendMessage.next(backspace);
    }

    SipSessionEventBus.outgoingInfo$.next(messageToClearVPText);
    VrsChatEventBus.$clearMessage.next();
  }
);
