import { useEffect } from 'react';
import type { UnknownAction } from '@reduxjs/toolkit';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { primaryHearingSelector } from 'features/call/call-hearing/store';
import { selectIsAcceptingUserAndCurrentlyTeaming } from 'features/teaming/teaming-base/store';
import { isDeafToHearingSelector } from 'features/call/call-base/store/selectors';
import { deafSelector } from 'features/call/call-deaf/store';
import type { CallDetailsStatusUpdateFromParticipantsData } from 'features/call/call-details/interfaces';

/**
 * NOTE: This code most likely to be removed after ARC-3199 complete.
 */
export const useBaseCallDetailsStatusUpdateFromParticipants = <T>(
  setCallDetailsStatusAction: (newStatus: T) => UnknownAction,
  getNewCallDetailsStatus: (
    participantsData: CallDetailsStatusUpdateFromParticipantsData
  ) => T
) => {
  const dispatch = useAppDispatch();
  const primaryHearing = useAppSelector(primaryHearingSelector);
  const isAcceptingUserAndCurrentlyTeaming = useAppSelector(
    selectIsAcceptingUserAndCurrentlyTeaming
  );
  const isDeafToHearing = useAppSelector(isDeafToHearingSelector);
  const deaf = useAppSelector(deafSelector);

  useEffect(() => {
    if (!isAcceptingUserAndCurrentlyTeaming) {
      return;
    }

    const newHearingCallDetailsStatus = getNewCallDetailsStatus({
      isDeafToHearing,
      deaf: {
        status: deaf.status,
      },
      hearing: {
        status: primaryHearing.status,
        hasConnected: primaryHearing.hasConnected,
      },
    });

    dispatch(setCallDetailsStatusAction(newHearingCallDetailsStatus));
  }, [
    dispatch,
    getNewCallDetailsStatus,
    setCallDetailsStatusAction,
    isAcceptingUserAndCurrentlyTeaming,
    isDeafToHearing,
    deaf.status,
    primaryHearing.status,
    primaryHearing.hasConnected,
  ]);
};
