import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CallInPhone } from 'assets/svg';
import { maskPhoneNumber } from 'features/phone-number/helpers';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';
import type { HearingParticipant } from 'features/call/call-hearing/interfaces';

interface AddHearingConnectingProps {
  onCancel: (id: string) => Promise<void>;
  hearing: HearingParticipant;
}

export const AddHearingConnecting = ({
  onCancel,
  hearing,
}: AddHearingConnectingProps) => {
  const { t: ct } = useTranslation();
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });

  return (
    <div>
      <Typography variant='label' color={theme.palette.grey[300]}>
        {t('connecting')}
      </Typography>
      <ParticipantWrapper>
        <CallInPhone height={20} width={20} />
        <Typography variant='body1' color={theme.palette.grey[50]}>
          {maskPhoneNumber(hearing.phoneNumber)}
        </Typography>
      </ParticipantWrapper>
      <Button
        data-testid='add-hearing-cancel-button'
        fullWidth
        size='small'
        loading={hearing.isLoading || !hearing.id}
        onClick={() => onCancel(hearing.id)}
      >
        {ct('cancel')}
      </Button>
    </div>
  );
};

const ParticipantWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  gap: ${theme.spacing(8)};
  margin-top: ${theme.spacing(4)};
  margin-bottom: ${theme.spacing(4)};
  align-items: center;
`;
