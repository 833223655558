/* eslint-disable import/no-internal-modules */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { t } from 'i18next';

import { showNotification } from './notificationSlice';

import { LoggerService } from 'features/logger/services';
import { notificationErrorAnalytics } from 'features/notification/helpers';
import type { HandleErrorPayload } from 'features/notification/interfaces';
/**
 * Gathers info for context about the error,
 * shows it to the user (optional),
 * and sends it to console, Sentry, and DataSet.
 */
export const handleError = createAsyncThunk(
  'app/handleError',
  async (
    {
      error,
      title,
      state,
      message,
      methodName,
      disableUserNotification = false,
      ignoreReporting = false,
    }: HandleErrorPayload,
    { dispatch }
  ) => {
    LoggerService.error(error, ignoreReporting);

    if (!disableUserNotification) {
      dispatch(
        showNotification({
          severity: 'error',
          title: title ?? t('somethingWentWrong'),
          message,
        })
      );
    }

    dispatch(
      notificationErrorAnalytics({
        methodName: methodName ?? 'handleError',
        error: error.message,
        state: state ?? {},
        message: message ?? '',
      })
    );
  }
);
