import { useState, useEffect } from 'react';
import { Box, Paper, Typography, Divider, styled } from '@mui/material';

import { RecentCallsTracker } from './RecentCallsTracker';

import { useAppSelector } from 'features/app/hooks';
import {
  selectCallerSessionId,
  selectCallerSessionStatus,
  selectSwitchboardConnections,
  selectWebRtcRemoteIdentities,
} from 'features/caller-session/store';
import { isDevOrQaEnvironment } from 'common/utils';
import { sipCallIdSelector } from 'features/call/call-base/store/selectors';
import { TerminationType } from 'features/call/call-base/enums';
import { selectCallPageStatus } from 'features/call/call-ui-state/store';
import theme from 'features/theme/theme';

const MENU_KEY = '*614';
const debugMenuVisible = Boolean(import.meta.env.VITE_APP_DEBUG_MENU_VISIBLE);

export const HiddenDebugMenu = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [typedKeys, setTypedKeys] = useState('');
  const callerSessionStatus = useAppSelector(selectCallerSessionStatus);
  const callerSessionId = useAppSelector(selectCallerSessionId);
  const sipCallId = useAppSelector(sipCallIdSelector);
  const isDevOrQa = isDevOrQaEnvironment;
  const webRtcConnections = useAppSelector(selectWebRtcRemoteIdentities);
  const switchboardConnections = useAppSelector(selectSwitchboardConnections);
  const callLog = RecentCallsTracker.getInstance().getCallLog();
  const callPageStatus = useAppSelector(selectCallPageStatus);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      setTypedKeys((prev) => (prev + event.key).slice(-MENU_KEY.length));
    };
    if (debugMenuVisible) {
      setIsVisible(true);
    }

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  useEffect(() => {
    if (typedKeys === MENU_KEY) {
      setIsVisible((prev) => !prev);
      setTypedKeys('');
    }
  }, [typedKeys]);

  if (!isDevOrQa || !isVisible) {
    return null;
  }

  return (
    <DebugContainer elevation={4}>
      <Section>
        <SectionTitle variant='subtitle2'>Session Information</SectionTitle>
        <StatusItem>
          Session Status: <StatusValue>{callerSessionStatus}</StatusValue>
        </StatusItem>
        <StatusItem>
          Call Page Status: <StatusValue>{callPageStatus}</StatusValue>
        </StatusItem>
        <StatusItem>
          Caller Session ID: <StatusValue>{callerSessionId}</StatusValue>
        </StatusItem>
        <StatusItem>
          SIP Call ID: <StatusValue>{sipCallId}</StatusValue>
        </StatusItem>
      </Section>

      <StyledDivider />

      <Section>
        <SectionTitle variant='subtitle2'>Connection Status</SectionTitle>
        <StatusItem>
          WebRTC Connections:{' '}
          <StatusValue>{webRtcConnections?.length || 0}</StatusValue>
        </StatusItem>
        <StatusItem>
          Switchboard:{' '}
          <StatusValue>{switchboardConnections?.length || 0}</StatusValue>
        </StatusItem>
      </Section>

      <StyledDivider />

      <Section>
        <SectionTitle variant='subtitle2'>Recent Calls</SectionTitle>
        {callLog
          ?.slice()
          .reverse()
          .map((call, idx: number) => (
            <CallLogItem key={call.callId}>
              <CallId variant='body2'>
                #{idx + 1}: {call.callId}
              </CallId>
              <TerminationCode variant='body2'>
                {TerminationType[call.terminationCode]}
              </TerminationCode>
            </CallLogItem>
          ))}
      </Section>
    </DebugContainer>
  );
};

const DebugContainer = styled(Paper)(() => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
  padding: theme.spacing(3),
  maxWidth: '600px',
  maxHeight: '80vh',
  overflowY: 'auto',
  zIndex: 9999,
  opacity: 0.97,
  backgroundColor: '#1a1a1a',
  color: '#fff',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.25)',
  '&:hover': {
    opacity: 1,
  },
}));

const Section = styled(Box)(() => ({
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(() => ({
  color: '#60a5fa',
  fontWeight: 700,
  marginBottom: theme.spacing(2),
  fontSize: '1.1rem',
  textTransform: 'uppercase',
  letterSpacing: '0.05em',
}));

const StatusValue = styled('strong')({
  color: '#4ade80',
  fontWeight: 600,
  fontFamily: 'monospace',
  wordBreak: 'break-all',
});

const StatusItem = styled(Typography)(() => ({
  display: 'grid',
  gridTemplateColumns: '180px 1fr',
  gap: theme.spacing(1),
  padding: theme.spacing(0.75, 1),
  color: '#e5e7eb',
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}));

const CallId = styled(Typography)({
  color: '#4ade80',
  fontWeight: 600,
  fontFamily: 'monospace',
});

const TerminationCode = styled(Typography)({
  color: '#f87171',
  fontWeight: 600,
});

const CallLogItem = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  color: '#e5e7eb',
  fontWeight: 500,
  marginBottom: theme.spacing(0.5),
}));

const StyledDivider = styled(Divider)(() => ({
  margin: theme.spacing(2, 0),
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
}));
