import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { createReduxEnhancer } from '@sentry/react';
import _ from 'lodash';

import type { PreloadedState } from './types';

import { callerSessionReducer } from 'features/caller-session/store';
import { appConfigReducer } from 'features/app-config/store';
import { appLoadingReducer } from 'features/app-loading/store';
import appReducer from 'features/app/store/app/appSlice';
import { audioReducer, callReducer } from 'features/call/call-base/store';
import { conferenceReducer } from 'features/call/call-conference/store';
import { callDeafReducer } from 'features/call/call-deaf/store';
import { callDetailsReducer } from 'features/call/call-details/store';
import { callHearingReducer } from 'features/call/call-hearing/store';
import { callTimerReducer } from 'features/call/call-timer/store';
import { vrsCallCommentsReducer } from 'features/call/vrs-call-comments/store';
import { vrsCallReducer } from 'features/call/vrs-call/store';
import { vrsConferenceCallReducer } from 'features/call/vrs-conference-call/store';
import { coldHandoffReducer } from 'features/cold-handoff/store';
import { customGreetingReducer } from 'features/custom-greeting/store';
import { deafPhoneNumberHiddenReducer } from 'features/deaf-phone-number-hidden/store';
import { dialPadReducer } from 'features/dial-pad/store';
import { dialedNumbersHistoryReducer } from 'features/dialed-numbers-history/store';
import { emergencyReducer } from 'features/emergency/store';
import { env } from 'features/environment/constants';
import { localVideoReducer } from 'features/local-video/store';
import { machinePreferencesReducer } from 'features/machine-preferences/store';
import { mediaDevicesReducer } from 'features/media-devices/store';
import monitorReducer from 'features/monitoring/store/monitoringSlice';
import { multiModeReducer } from 'features/multi-mode/store';
import { notificationReducer } from 'features/notification/store';
import { queueSettingsReducer } from 'features/queue-settings/store';
import { reportingReducer } from 'features/reporting/store';
import { sessionReducer } from 'features/session/store';
import { signMailReducer } from 'features/signmail/store';
import { spawnCallReducer } from 'features/spawn-call/store';
import { statsReducer } from 'features/stats/store';
import { teamingReducer } from 'features/teaming/teaming-base/store';
import { userReducer } from 'features/user/store';
import { vcoReducer } from 'features/vco/store';
import { videoPrivacyReducer } from 'features/video-privacy/store';
import { voiceSessionReducer } from 'features/voice-session/store';
import { vpnDetectorReducer } from 'features/vpn-detector/store';
import { vriChatReducer } from 'features/vri-chat/store';
import { vrsChatReducer } from 'features/vrs-chat/store';
import { callUiManagerReducer } from 'features/call/call-ui-state/store';
import { stateTransitionMiddleware } from 'features/call/call-ui-state/middleware';

export const rootReducer = combineReducers({
  app: appReducer,
  appConfig: appConfigReducer,
  appLoading: appLoadingReducer,
  audio: audioReducer,
  call: callReducer,
  callDeaf: callDeafReducer,
  callDetails: callDetailsReducer,
  callerSession: callerSessionReducer,
  callHearing: callHearingReducer,
  callTimer: callTimerReducer,
  callUiManager: callUiManagerReducer,
  coldHandoff: coldHandoffReducer,
  conference: conferenceReducer,
  customGreeting: customGreetingReducer,
  deafPhoneNumberHidden: deafPhoneNumberHiddenReducer,
  dialedNumbersHistory: dialedNumbersHistoryReducer,
  dialPad: dialPadReducer,
  emergency: emergencyReducer,
  localVideo: localVideoReducer,
  machinePreferences: machinePreferencesReducer,
  mediaDevices: mediaDevicesReducer,
  monitor: monitorReducer,
  multiMode: multiModeReducer,
  notification: notificationReducer,
  queueSettings: queueSettingsReducer,
  reporting: reportingReducer,
  session: sessionReducer,
  signMail: signMailReducer,
  spawnCall: spawnCallReducer,
  stats: statsReducer,
  teaming: teamingReducer,
  user: userReducer,
  vco: vcoReducer,
  videoPrivacy: videoPrivacyReducer,
  voiceSession: voiceSessionReducer,
  vpnDetector: vpnDetectorReducer,
  vriChat: vriChatReducer,
  vrsCall: vrsCallReducer,
  vrsCallComments: vrsCallCommentsReducer,
  vrsChat: vrsChatReducer,
  vrsConferenceCall: vrsConferenceCallReducer,
});

const sentryReduxEnhancer = createReduxEnhancer({
  configureScopeWithState: (scope, state) => {
    const { call, user, session, voiceSession } = state;
    const neededTagsMapping = {
      callId: call?.id,
      sipCallId: call?.sipCallId,
      email: user?.user?.email,
      rnsConnectionId: session?.rnsConnectionId,
      sessionId: session?.sessionId,
      voiceSessionId: voiceSession?.sessionId,
    };
    Object.entries(neededTagsMapping).forEach(([tag, value]) => {
      if (value) {
        scope.setTag(tag, value);
      }
    });
  },
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    preloadedState,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: false,
      }).concat(stateTransitionMiddleware),
    enhancers: (getDefaultEnhancers) =>
      getDefaultEnhancers().concat(sentryReduxEnhancer),
    devTools: env.isDevelopmentBuild && {
      maxAge: 200,
    },
  });
};
/**
 * Guarantees that only one Redux store will be set up,
 * and the same store will always be returned.
 *
 * (The only exception is during local development,
 * since hot module replacement will cause this to be redefined
 * each time the dev server performs HMR.)
 */
export const setupStoreOnce = _.once(setupStore);
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
