import type { RefObject } from 'react';

export const processInputMessage = (
  inputRef: RefObject<HTMLTextAreaElement>,
  e: KeyboardEvent
) => {
  if (inputRef.current?.value.length && e.code === 'Backspace') {
    inputRef.current.value = inputRef.current.value.slice(0, -1);
    return '\b';
  } else if (e.key === 'Enter') {
    inputRef.current!.value += '\n';
    return '\r\n';
  } else if (inputRef.current && e.key.length === 1) {
    inputRef.current.value += e.key;
    return e.key;
  }
};
