export enum CallPageStatus {
  IDLE = 'IDLE',
  CALL_RECEIVED = 'CALL_RECEIVED',
  TEAMING_OFFER_ACCEPTED = 'TEAMING_OFFER_ACCEPTED',
  COLD_HANDOFF_OFFER_ACCEPTED = 'COLD_HANDOFF_OFFER_ACCEPTED',
  CONNECTED = 'CONNECTED',
  SURVEY = 'SURVEY',
  TEAMING_OFFER_CANCELED = 'TEAMING_OFFER_CANCELED',
  CALL_TRANSFER_FAILED = 'CALL_TRANSFER_FAILED',
}
