import { InputBase, styled, Tooltip } from '@mui/material';
import { typography } from '@mui/system';
import { type FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { filter } from 'rxjs';

import { useAppSelector } from 'features/app/hooks';
import { SipSessionEventBus } from 'features/sip/services';
import theme from 'features/theme/theme';
import { useWhileComponentMounted } from 'features/utils/hook';
import { VrsChatMessage } from 'features/vrs-chat/components';
import { VrsChatStatus } from 'features/vrs-chat/enums';
import { useAutoResizableTextarea } from 'features/vrs-chat/hooks';
import { VrsChatEventBus } from 'features/vrs-chat/services';
import {
  isShowPopupSelector,
  selectVrsChatStatus,
} from 'features/vrs-chat/store';
import { processInputMessage } from 'features/vrs-chat/helpers';

interface VrsChatMessageLocalProps {
  userName?: string;
}

const encoder = new TextEncoder();

const TOOLTIP_MESSAGES_MAP: Record<VrsChatStatus, string> = {
  [VrsChatStatus.Connecting]: 'connecting',
  [VrsChatStatus.FailedToConnect]: 'messagesDisabled',
  [VrsChatStatus.Disconnected]: '',
  [VrsChatStatus.Connected]: '',
};

export const VrsChatMessageLocal: FC<VrsChatMessageLocalProps> = ({
  userName,
}) => {
  const isShowPopup = useAppSelector(isShowPopupSelector);
  const status = useAppSelector(selectVrsChatStatus);
  const { t } = useTranslation();
  const whileComponentMounted = useWhileComponentMounted();

  const inputRef = useAutoResizableTextarea();
  const messageBufferRef = useRef('');
  const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const isDisabled = status !== VrsChatStatus.Connected;

  const sendMessage = (signal: AbortSignal) => {
    if (signal.aborted) {
      return;
    }

    if (messageBufferRef.current.length > 0) {
      const messageToSend = messageBufferRef.current.substring(0, 19);
      messageBufferRef.current = messageBufferRef.current.substring(19);

      const encodedMessage = encoder.encode(messageToSend);
      VrsChatEventBus.$sendMessage.next(encodedMessage);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  };

  const clearMessage = () => {
    const length = inputRef.current?.value.length;
    if (length) {
      for (let i = 0; i < length; i++) {
        addNewMessageToChat('\b', abortControllerRef.current!.signal);
      }
      inputRef.current.value = '';
    }
  };

  const sendSignMailMessage = (signMailText: string) => {
    inputRef.current!.value = signMailText;
    addNewMessageToChat(signMailText, abortControllerRef.current!.signal);
  };

  const addNewMessageToChat = (inputValue: string, signal: AbortSignal) => {
    if (signal.aborted) {
      return;
    }

    messageBufferRef.current += inputValue;

    if (!intervalRef.current) {
      intervalRef.current = setInterval(() => sendMessage(signal), 500);
    }
  };

  const handleClearChat = () => {
    inputRef.current!.value = '';
    messageBufferRef.current = '';
  };
  useEffect(() => {
    const subscription = SipSessionEventBus.incomingInfo$
      .pipe(filter((info) => info.body.includes('00110000')))
      .subscribe(async (info) => {
        const match = info.contentType.match(/application\/sorenson/);
        if (match) {
          VrsChatEventBus.$clearMessage.next();
        }
      });

    return () => subscription.unsubscribe();
  }, []);

  const abortControllerRef = useRef<AbortController>();

  useEffect(() => {
    abortControllerRef.current = new AbortController();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      abortControllerRef.current?.abort();
    };
  }, []);

  useEffect(() => {
    if (isShowPopup) {
      inputRef.current?.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowPopup]);

  useEffect(() => {
    VrsChatEventBus.$clearMessage
      .pipe(whileComponentMounted())
      .subscribe(clearMessage);
    VrsChatEventBus.$sendSignMailMessage
      .pipe(whileComponentMounted())
      .subscribe(sendSignMailMessage);
    VrsChatEventBus.$closeDataChannel
      .pipe(whileComponentMounted())
      .subscribe(handleClearChat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tooltipMessage = TOOLTIP_MESSAGES_MAP[status];

  const handleInput = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    e.preventDefault();
    const inputValue = processInputMessage(
      inputRef,
      e.nativeEvent as unknown as KeyboardEvent
    );

    if (inputValue && abortControllerRef.current) {
      addNewMessageToChat(inputValue, abortControllerRef.current.signal);
      // Force resize after processing input
      const textarea = inputRef.current;
      if (textarea) {
        textarea.style.height = 'auto';
        textarea.style.height = `${textarea.scrollHeight}px`;
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    e.stopPropagation();
    handleInput(e as unknown as React.ChangeEvent<HTMLTextAreaElement>);
  };

  const messageInput = (
    <UserMessageInput
      disabled={isDisabled}
      inputRef={inputRef}
      data-testid='vrs-chat-message-local'
      placeholder={t('startTyping')}
      onPaste={(e) => e.preventDefault()}
      onChange={handleInput}
      onKeyDown={handleKeyDown}
      readOnly={false}
      multiline
    />
  );

  return (
    <Container>
      <VrsChatMessage userName={userName}>
        {tooltipMessage ? (
          <Tooltip title={t(tooltipMessage)} arrow>
            {messageInput}
          </Tooltip>
        ) : (
          messageInput
        )}
      </VrsChatMessage>
    </Container>
  );
};

const Container = styled('div')`
  border-radius: 12px 12px 0 12px;
  background-color: ${theme.palette.primary[900]};
`;

const UserMessageInput = styled(InputBase)`
  background-color: ${theme.palette.primary[900]};
  border: none;
  width: 100%;
  ${typography(theme.typography.body2)};

  .MuiInputBase-input {
    padding: 0;
    overflow: hidden;
    resize: none;
    min-height: 24px;
  }
`;
