import { Typography } from '@mui/material';
import { typography } from '@mui/system';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useIMask } from 'react-imask';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  setHearingName,
  setPrimaryHearingPhoneNumber,
} from 'features/call/call-hearing/store';
import { isCollaborationInProgressSelector } from 'features/teaming/teaming-base/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Button } from 'features/ui/elements';

interface SpawnCallProps {
  onClose: () => void;
  isCloseButtonVisible: boolean;
  onSpawnCall: (phoneNumber: string) => void;
}

export const SpawnCall: FC<SpawnCallProps> = ({
  onClose,
  isCloseButtonVisible,
  onSpawnCall,
}) => {
  const isCollaborationInProgress = useAppSelector(
    isCollaborationInProgressSelector
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation('translation', { keyPrefix: 'newCall' });
  const { t: ct } = useTranslation();

  const { ref, unmaskedValue } = useIMask<HTMLInputElement>({
    mask: [
      { mask: '+0 (0) 000-000' },
      { mask: '+0 (000) 000-0000' },
      { mask: '+00 (000) 000-0000' },
      { mask: '+000 (000) 0000-00000' },
      { mask: '+000' },
      { mask: '000' },
    ],
  });

  const handleHearingCall = async () => {
    dispatch(setPrimaryHearingPhoneNumber(unmaskedValue));
    dispatch(
      setHearingName({
        name: ct('unknownHearingName'),
        phoneNumber: unmaskedValue,
      })
    );
    onSpawnCall(unmaskedValue);
  };

  return (
    <Container>
      <Head>
        <Title variant='subtitle1'>{t('title')}</Title>
        {isCloseButtonVisible && (
          <CloseButton title='close' onClick={onClose}>
            &#x2715;
          </CloseButton>
        )}
      </Head>
      <PhoneNumberLabelInput variant='label'>
        {t('label')}
      </PhoneNumberLabelInput>
      <PhoneNumberInput ref={ref} autoFocus autoComplete='chrome-off' />
      <Button
        fullWidth
        size='small'
        title={t('btnText')}
        disabled={isCollaborationInProgress}
        onClick={handleHearingCall}
      >
        {t('btnText')}
      </Button>
    </Container>
  );
};

const Container = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: ${theme.spacing(8)};
  border-radius: 8px;
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
`;

const CloseButton = styled('span')`
  cursor: pointer;
`;

const Title = styled(Typography)`
  color: ${theme.palette.common.white};
`;

const PhoneNumberInput = styled('input')`
  ${typography(theme.typography.body1)};
  max-width: 100%;
  background-color: ${theme.palette.common.white};
  padding: ${theme.spacing(4, 6)};
  margin-bottom: ${theme.spacing(6)};
  border-radius: 8px;
  border: none;
  outline: none;
`;

const PhoneNumberLabelInput = styled(Typography)`
  color: ${theme.palette.grey[200]};
  bottom: ${theme.spacing(2)};
`;
