import { styled } from '@mui/material';
import { typography } from '@mui/system';
import type { FC, PropsWithChildren } from 'react';

import theme from 'features/theme/theme';

interface VrsChatMessageProps {
  userName?: string;
}

export const VrsChatMessage: FC<PropsWithChildren<VrsChatMessageProps>> = ({
  children,
  userName,
}) => {
  return (
    <Container>
      <UserInfo>{userName ?? ''}</UserInfo>
      {children}
    </Container>
  );
};

const UserInfo = styled('div')`
  margin-bottom: ${theme.spacing(4)};
  ${typography(theme.typography.caption)};
  color: ${theme.palette.grey[100]};
`;

const Container = styled('div')`
  max-width: 240px;
  padding: ${theme.spacing(8)};
`;
