import { styled } from '@mui/material';

import { EmergencyCallDetails } from './EmergencyCallDetails';

import { CallDetailsAccordianMenu } from 'features/call/call-details/components';
import theme from 'features/theme/theme';

export const EmergencyCallDetailsContainer = () => {
  return (
    <CallDetailsAccordianMenu>
      <Container>
        <EmergencyCallDetails />
      </Container>
    </CallDetailsAccordianMenu>
  );
};

const Container = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacing(10, 10)};
`;
