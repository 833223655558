import {
  createAsyncThunk,
  type Action,
  type ThunkAction,
} from '@reduxjs/toolkit';
import { t } from 'i18next';

import {
  selectIsSignMailSessionInProgress,
  selectShouldContinueSignMailSession,
  selectSignMailStatus,
  signMailRecordingUploadUrlSelector,
  textStartSecondsSelector,
} from './signMailSelectors';
import {
  _resetSignMail,
  _transitionSignMailStatus,
  recordingErrorOccurred,
  recordingUploadCompleted,
  recordingUploadStarted,
  startSignmailSession,
} from './signMailSlice';

import { sendAnalyticsInfo } from 'features/analytics/helpers';
import type { RootState } from 'features/app/store/store';
import {
  ConnectionChangeType,
  InterpretingSessionType,
  TerminationType,
} from 'features/call/call-base/enums';
import { removeAnyLeadingNonNumbers } from 'features/call/call-base/helpers';
import { CallDaoService } from 'features/call/call-base/services';
import { setTerminationType } from 'features/call/call-base/store';
import {
  callIdSelector,
  isHearingCallerIdBlockedSelector,
} from 'features/call/call-base/store/selectors';
import { deafPhoneNumberSelector } from 'features/call/call-deaf/store';
import {
  isPrimaryHearingConnectedSelector,
  primaryHearingPhoneNumberSelector,
} from 'features/call/call-hearing/store';
import { ParticipantsStatusEventBus } from 'features/call/call-status/services';
import { hideLocalVideo, showLocalVideo } from 'features/local-video/store';
import { handleError, showNotification } from 'features/notification/store';
import { sessionIdSelector } from 'features/session/store';
import { videoRecordingBitrate } from 'features/signmail/constants';
import { SignMailStatus } from 'features/signmail/enums';
import {
  getGreetingVideoUrl,
  canTransitionToSignMailStatus,
} from 'features/signmail/helpers';
import {
  SignMailDaoService,
  SignMailRecorderService,
} from 'features/signmail/services';
import {
  isSignMailConnectRecordedSelector,
  resetSignMailConnectRecord,
} from 'features/reporting/store';
import { ANONYMOUS } from 'features/voice-meeting/constants';
import { isStringAllLettersOrEmpty } from 'common/helpers';

export const sendSignMailConnectionCallState = createAsyncThunk(
  'call/sendSignMailConnectionCallState',
  async (
    connectionChangeType: ConnectionChangeType,
    { dispatch, getState }
  ) => {
    const state = getState() as RootState;
    const callId = callIdSelector(state);
    const isSignMailConnectRecorded = isSignMailConnectRecordedSelector(state);
    const isHearingConnected = isPrimaryHearingConnectedSelector(state);
    const sessionLoginHistoryId = sessionIdSelector(state);

    // ensures multiple disconnects are not sent
    if (
      !isSignMailConnectRecorded &&
      connectionChangeType ===
        ConnectionChangeType.AGENT_DISCONNECT_HEARING_TO_DEAF_MESSAGE
    ) {
      return;
    }
    dispatch(resetSignMailConnectRecord());
    try {
      await CallDaoService.connectionStateHistory({
        callId,
        connectionChangeType,
        isHearingConnected,
        sessionLoginHistoryId,
        isDeafConnected:
          connectionChangeType ===
          ConnectionChangeType.AGENT_CONNECT_HEARING_TO_DEAF_MESSAGE,
        interpretingSessionType:
          InterpretingSessionType.HEARING_TO_DEAF_MESSAGE,
      });
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'sendSignMailConnectionCallState',
        })
      );
    }
  }
);
/**
 * Determines whether a new SignMail session should be started.
 * @returns {Promise<boolean>} - `true` if a new SignMail session should be started, `false` if a session is already in progress.
 */
export const shouldStartNewSignMailSession =
  (): ThunkAction<Promise<boolean>, RootState, unknown, Action> =>
  async (dispatch: any, getState: () => RootState) => {
    const state = getState();
    const isSignMailInProgress = selectIsSignMailSessionInProgress(state);
    if (isSignMailInProgress) {
      return false;
    }
    return true;
  };

/**
 * Starts a new SignMail session and prepares the greeting for the deaf user.
 * This function is responsible for the following tasks:
 * - Starts a valid SignMail session by dispatching the `startValidSignmailSession` action.
 * - If this is a hearing-to-deaf call, sets the termination type to `TerminationType.SignMail`.
 * - Fetches the deaf user's greeting information from the `SignMailDaoService`.
 * - Sets the SignMail status to either `SignMailStatus.GREETING` or `SignMailStatus.COUNTDOWN` based on the greeting information.
 *
 * @param {any} payload - The payload for the async thunk action.
 * @param {any} { dispatch, getState } - The dispatch and getState functions from the Redux store.
 * @returns {Promise<void>} - A Promise that resolves when the operation is complete.
 */
export const startSignMailSessionAndPrepareGreeting = createAsyncThunk(
  'signMail/startSignMailSessionAndPrepareGreeting',
  async (payload, { dispatch, getState }) => {
    const state = getState() as RootState;
    const deafPhoneNumber = deafPhoneNumberSelector(state);
    const isHearingCallerIdBlocked = isHearingCallerIdBlockedSelector(state);

    let hearingNumberToResolveWithCore = removeAnyLeadingNonNumbers(
      primaryHearingPhoneNumberSelector(state)
    );

    if (isStringAllLettersOrEmpty(hearingNumberToResolveWithCore)) {
      hearingNumberToResolveWithCore = ANONYMOUS;
    }
    try {
      const { VideoMsgData } = await SignMailDaoService.getDeafUserInformation({
        calleeNumber: deafPhoneNumber,
        callerNumber: hearingNumberToResolveWithCore,
        blockCallerId: isHearingCallerIdBlocked,
      });

      const videoUrl = getGreetingVideoUrl(VideoMsgData);
      dispatch(
        startSignmailSession({
          callId: state.call.id,
          greeting: {
            videoUrl,
            text: VideoMsgData.GreetingText,
            greetingPreference: VideoMsgData.GreetingPreference,
          },
        })
      );
      dispatch(setTerminationType(TerminationType.SignMail));
      ParticipantsStatusEventBus.deaf.$unavailable.next();
      ParticipantsStatusEventBus.deaf.$connectSignMail.next();

      if (VideoMsgData.GreetingURL || VideoMsgData.GreetingText) {
        dispatch(changeSignMailStatus(SignMailStatus.GREETING));
      } else {
        dispatch(
          sendAnalyticsInfo({
            message: 'No greeting found attempting to start countdown',
          })
        );
        dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
      }
    } catch (error) {
      dispatch(
        handleError({
          error,
          methodName: 'prepareCustomerGreeting',
        })
      );
      ParticipantsStatusEventBus.deaf.$disconnectSignMail.next();
      dispatch(resetSignMailState());
    }
  }
);
/**
 * Dispatches an action to change the SignMail status to COUNTDOWN.
 * This function is called when the greeting has ended, and the countdown
 * to the start of the SignMail recording should begin.
 */
export const startSignMailCountdown =
  (): ThunkAction<void, RootState, unknown, Action> => (dispatch) => {
    dispatch(changeSignMailStatus(SignMailStatus.COUNTDOWN));
  };

/**
 * Dispatches an action to finish the SignMail recording process.
 * This function is responsible for uploading the recorded video, setting the
 * necessary metadata, and updating the state to reflect the successful
 * completion of the recording.
 *
 * @param {object} - An object containing the text to be included in the
 * SignMail message.
 * @param {string} text - The text to be included in the SignMail message.
 */
export const finishRecording = createAsyncThunk(
  'signMail/finishRecording',
  async ({ text }: { text: string }, { dispatch, getState }) => {
    const rootState = getState() as RootState;
    const uploadUrl = signMailRecordingUploadUrlSelector(rootState);
    const callId = callIdSelector(rootState);
    const service = SignMailRecorderService.getInstance();
    try {
      const signMailCallId = rootState.signMail.signMailSessionCallId;
      // under normal circumstances, this should not happen this is one last check before we send off the recording in case thunks get dispatched out of order.
      if (callId !== signMailCallId) {
        dispatch(
          sendAnalyticsInfo({
            method: 'finishRecording',
            message: `Sign mail call id ${signMailCallId} does not match with current call id ${callId} will not send sign mail recording`,
          })
        );
        return;
      } else {
        dispatch(
          sendAnalyticsInfo({
            method: 'finishRecording',
            message: `Sign mail call id ${signMailCallId} matches with current call id ${callId} will send sign mail recording`,
          })
        );
      }
      const deafPhoneNumber = deafPhoneNumberSelector(rootState);
      const hearingPhoneNumber = primaryHearingPhoneNumberSelector(rootState);
      const textStartSeconds = textStartSecondsSelector(rootState);
      const { file, duration } = await service.stop(callId);
      dispatch(recordingUploadStarted());
      dispatch(sendAnalyticsInfo({ message: 'Starting upload of sign mail' }));
      const messageId = await SignMailDaoService.uploadFile(uploadUrl, file);
      const signMailRecordingSettings = {
        Bitrate: videoRecordingBitrate,
        Codec: 'H264',
        Level: '2.2',
        Profile: 'Baseline',
        TextStartSeconds: textStartSeconds.toString(),
        MessageSeconds: duration,
        Text: text
          ? `Phone: \r\n${hearingPhoneNumber}\r\nNote: \r\n${text}`
          : '',
        TextStopSeconds: duration.toString(),
        ToPhoneNumber: deafPhoneNumber,
        MessageId: messageId,
      };

      await SignMailDaoService.uploadUrlReady(
        callId,
        signMailRecordingSettings
      );

      sendAnalyticsInfo({
        message: 'SignMail recording uploaded successfully',
        Method: 'finishRecording',
      });
      dispatch(
        showNotification({
          title: t('signMail.successfullySent'),
          severity: 'success',
        })
      );
      ParticipantsStatusEventBus.deaf.$disconnected.next({
        connectionChangeType: ConnectionChangeType.AGENT_DISCONNECT_DEAF,
      });
      dispatch(
        sendAnalyticsInfo({
          message: 'SignMail message sent successfully',
          Method: 'finishRecording',
        })
      );
    } catch (error) {
      dispatch(recordingErrorOccurred());
      dispatch(
        handleError({
          error,
          methodName: 'finishRecording',
        })
      );
      dispatch(resetSignMailState());
    } finally {
      dispatch(recordingUploadCompleted());
      dispatch(resetSignMailState());
    }
  }
);

/**
 * Changes the status of the SignMail feature.
 *
 * This function is an asynchronous thunk that dispatches actions to update the SignMail status in the application state.
 * It checks the current status and whether a valid SignMail session is in progress before transitioning to the new status.
 * If the transition is invalid or a valid session is not in progress, it resets the SignMail state and sends analytics information.
 * Otherwise, it hides the local video, transitions to the new status, and sends analytics information about the status change.
 *
 * @param status - The new SignMail status to transition to.
 * @returns A Promise that resolves when the status change is complete.
 */
export const changeSignMailStatus = createAsyncThunk(
  'signMail/changeSignMailStatus',
  async (status: SignMailStatus, { dispatch, getState }) => {
    const state = getState() as RootState;
    const currentStatus = selectSignMailStatus(state);
    const shouldContinueSignMailSession =
      selectShouldContinueSignMailSession(state);

    if (!canTransitionToSignMailStatus(currentStatus, status)) {
      dispatch(
        sendAnalyticsInfo({
          method: 'changeSignMailStatus',
          message: `Invalid signmail status transition from ${currentStatus} to ${status}`,
        })
      );
      dispatch(resetSignMailState());
      return;
    }

    if (!shouldContinueSignMailSession) {
      dispatch(
        sendAnalyticsInfo({
          method: 'changeSignMailStatus',
          message: `Valid signmail session not in progress will not change signmail status to ${status}`,
        })
      );
      dispatch(resetSignMailState());
      return;
    }

    dispatch(hideLocalVideo());
    dispatch(_transitionSignMailStatus(status));
    dispatch(
      sendAnalyticsInfo({
        method: 'changeSignMailStatus',
        message: `SignMail status changed to ${status}`,
      })
    );
  }
);
export const resetSignMailState = createAsyncThunk(
  'signMail/resetSignMailState',
  async (_, { dispatch }) => {
    dispatch(showLocalVideo());
    dispatch(_resetSignMail());
    const service = SignMailRecorderService.getInstance();
    service.destroy();
    dispatch(
      sendAnalyticsInfo({
        method: 'resetSignMailState',
        message: 'SignMail state reset and service destroyed',
      })
    );
  }
);
