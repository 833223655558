export const processIncomingMessage = (target: string, message: string) => {
  return [...message].reduce((acc, char) => {
    if (char === '\b') {
      return acc.slice(0, -1);
    }
    if (char === '\u2028') {
      return acc + char.replaceAll('\u2028', '\n');
    }
    return acc + char;
  }, target);
};
