import { sendAnalyticsInfo } from 'features/analytics/helpers';
import { EmergencyLogMessages } from 'features/emergency/enums';
import type { EmergencyAnalytics } from 'features/emergency/interfaces';

export const regularEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: EmergencyLogMessages.REGULAR_EMERGENCY_CALL,
  });
};

export const spawnEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
  newCallId,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: EmergencyLogMessages.SPAWN_EMERGENCY_CALL,
    newCallId: newCallId,
  });
};

export const dialbackEmergencyCallAnalytics = ({
  methodName,
  isProvisioned,
  isGeoCoordinateAvailable,
  verify911Address,
}: EmergencyAnalytics) => {
  return sendAnalyticsInfo({
    Method: methodName,
    IsProvisioned: isProvisioned,
    IsGeoCoordinateAvailable: isGeoCoordinateAvailable,
    Verify911Address: verify911Address,
    Message: EmergencyLogMessages.DIALBACK_EMERGENCY_CALL,
  });
};
