import { httpClient } from 'common/services';

interface SendDtmfRequest {
  hearingParticipantId: string;
  sessionId: string;
  digit: string;
}

async function sendDtmf({
  digit,
  hearingParticipantId,
  sessionId,
}: SendDtmfRequest) {
  const route = `/voice-sessions/${sessionId}/participants/${hearingParticipantId}/dtmf`;
  const response = await httpClient.post<void>(route, { digit });
  return response.data;
}

export const DialPadDaoService = {
  sendDtmf,
};
