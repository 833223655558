import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useBaseCallDetailsStatusUpdateFromParticipants } from 'features/call/call-details/hooks';
import type { CallDetailsStatusUpdateFromParticipantsData } from 'features/call/call-details/interfaces';
import { primaryHearingSelector } from 'features/call/call-hearing/store';
import {
  EmergencyCallDetailsStatus,
  EmergencyConnectionPoint,
} from 'features/emergency/enums';
import {
  addCalledConnectionPoints,
  phoneNumberECRCSelector,
  phoneNumberPSAPSelector,
  setEmergencyCallDetailsStatus,
  setEmergencyConnectionPoint,
  setIsEmergencyOccurredInRegisteredLocation,
} from 'features/emergency/store';
import { selectIsAcceptingUserAndCurrentlyTeaming } from 'features/teaming/teaming-base/store';

const getCallDetailsStatus = ({
  hearing,
}: CallDetailsStatusUpdateFromParticipantsData) => {
  switch (hearing.status) {
    case 'connecting':
    case 'disconnected':
      return hearing.hasConnected
        ? EmergencyCallDetailsStatus.CALLEE_RECONNECT
        : EmergencyCallDetailsStatus.CALLEE_DISCONNECTED;

    case 'connected':
      return EmergencyCallDetailsStatus.CALLEE_CONNECTED;
  }
};

export const useEmergencyCallDetailsUpdateFromPrimaryHearing = () => {
  const dispatch = useAppDispatch();

  const primaryHearing = useAppSelector(primaryHearingSelector);
  const phoneNumberPSAP = useAppSelector(phoneNumberPSAPSelector);
  const phoneNumberECRC = useAppSelector(phoneNumberECRCSelector);
  const isAcceptingUserAndCurrentlyTeaming = useAppSelector(
    selectIsAcceptingUserAndCurrentlyTeaming
  );

  useBaseCallDetailsStatusUpdateFromParticipants(
    setEmergencyCallDetailsStatus,
    getCallDetailsStatus
  );

  useEffect(() => {
    if (
      !(
        isAcceptingUserAndCurrentlyTeaming &&
        primaryHearing.status === 'connected'
      )
    ) {
      return;
    }

    const isEmergencyPSAP = primaryHearing.phoneNumber === phoneNumberPSAP;
    const connectionPoint = isEmergencyPSAP
      ? EmergencyConnectionPoint.PSAP
      : primaryHearing.phoneNumber === phoneNumberECRC
        ? EmergencyConnectionPoint.ECRC
        : EmergencyConnectionPoint.OTHER;

    dispatch(setEmergencyConnectionPoint(connectionPoint));
    dispatch(addCalledConnectionPoints(connectionPoint));

    if (connectionPoint === EmergencyConnectionPoint.OTHER) {
      return;
    }

    dispatch(setIsEmergencyOccurredInRegisteredLocation(isEmergencyPSAP));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    primaryHearing.status,
    primaryHearing.phoneNumber,
    isAcceptingUserAndCurrentlyTeaming,
    dispatch,
  ]);
};
