import { isStringAllLettersOrEmpty } from 'common/helpers/';
import {
  addPlusToPhoneNumber,
  parsePhoneNumber,
  parsePhoneNumberIncomingCall,
} from 'features/call/call-base/helpers';
import type { Call } from 'features/call/call-base/interfaces';
import { translateHearingName } from 'features/call/call-hearing/helpers';
import type { HearingParticipant } from 'features/call/call-hearing/interfaces';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';

export const adaptIncomingPrimaryHearing = (
  call: Call,
  isHearingToDeaf: boolean
): HearingParticipant => {
  let incomingNumber = call.HearingConnectionString;
  if (isStringAllLettersOrEmpty(incomingNumber) && isHearingToDeaf) {
    incomingNumber = 'anonymous';
  } else {
    incomingNumber = parsePhoneNumberIncomingCall(incomingNumber);
  }
  return {
    id: '',
    name: translateHearingName(call.HearingNameString),
    phoneNumber: incomingNumber,
    phoneExtension: call.HearingPhoneExtension,
    location: call.HearingConnectionLocation,
    status: 'disconnected',
    type: HearingParticipantType.PRIMARY,
    isLoading: false,
  };
};

export const adaptIncomingVco2Line = (
  carryOverPhoneNumber: string
): HearingParticipant => {
  return {
    id: '',
    name: '',
    location: '',
    phoneExtension: '',
    phoneNumber: addPlusToPhoneNumber(parsePhoneNumber(carryOverPhoneNumber)),
    status: 'disconnected',
    type: HearingParticipantType.VCO2LINE,
    isLoading: false,
  };
};
