import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import type { FC, PropsWithChildren, ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { envConfig } from 'features/environment/services';

export const LaunchDarklyProvider: FC<PropsWithChildren> = ({ children }) => {
  const [provider, setProvider] = useState<ReactElement | null>(null);

  const getProvider = async () => {
    const LDProvider = await asyncWithLDProvider({
      clientSideID: envConfig.launchDarklyClientSideId,
      context: {
        kind: 'user',
        anonymous: true,
        wantsAllModes: localStorage.getItem(
          'launchDarklyContext/wantsAllModes'
        ),
      },
    });

    setProvider(<LDProvider>{children}</LDProvider>);
  };

  useEffect(() => {
    getProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return provider;
};
