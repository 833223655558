import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

export const CallDetailsButtonDisconnect: FC<
  Pick<ButtonProps, 'onClick' | 'loading' | 'hotkey' | 'disabled' | 'sx'>
> = ({ loading, onClick, hotkey, disabled, sx }) => {
  const { t } = useTranslation();

  return (
    <Button
      data-testid='call-details-disconnect-button'
      fullWidth
      size='small'
      color='error'
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      hotkey={hotkey}
      sx={sx}
    >
      {t('disconnect')}
    </Button>
  );
};
