import type { FC } from 'react';

import { useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import { NewCallee } from 'features/call/call-details/components';
import { Separator } from 'features/call/call-details/styles';
import {
  EmergencyCallDetailsAddNumberVco2Line,
  EmergencyCallDetailsCalleeConnected,
  EmergencyCallDetailsCalleeDisconnected,
  EmergencyCallDetailsCalleeReconnect,
  EmergencyCallDetailsChangeNumberVco2Line,
  EmergencyInfo,
  EmergencyNewCall,
} from 'features/emergency/components';
import { EmergencyCallDetailsStatus } from 'features/emergency/enums';
import {
  useEmergencyAutoConnect,
  useEmergencyCallDetailsParticipantHandler,
  useEmergencyCallDetailsStatusHandler,
  useEmergencyCallDetailsUpdateFromPrimaryHearing,
  useEmergencyDeafReconnect,
} from 'features/emergency/hooks';
import {
  emergencyCallDetailsStatusSelector,
  isOtherConnectionPointSelector,
} from 'features/emergency/store';
import { SignMailText } from 'features/signmail/components';
import { selectIsAcceptingUserAndCurrentlyTeaming } from 'features/teaming/teaming-base/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const EmergencyCallDetails: FC = () => {
  const emergencyCallDetailsStatus = useAppSelector(
    emergencyCallDetailsStatusSelector
  );
  const isOtherConnectionPoint = useAppSelector(isOtherConnectionPointSelector);
  const isAcceptingUserAndCurrentlyTeaming = useAppSelector(
    selectIsAcceptingUserAndCurrentlyTeaming
  );
  const { handleHearingSpawnNewCall } = useCallHearingParticipantHandlers();

  useEmergencyCallDetailsUpdateFromPrimaryHearing();
  useEmergencyCallDetailsParticipantHandler();
  useEmergencyCallDetailsStatusHandler();
  useEmergencyDeafReconnect();
  useEmergencyAutoConnect();

  return (
    <>
      <EmergencyInfoContainer>
        <EmergencyInfo />
      </EmergencyInfoContainer>
      {emergencyCallDetailsStatus ===
        EmergencyCallDetailsStatus.CALLEE_DISCONNECTED && (
        <EmergencyCallDetailsCalleeDisconnected />
      )}
      {emergencyCallDetailsStatus ===
        EmergencyCallDetailsStatus.CALLEE_CONNECTED && (
        <EmergencyCallDetailsCalleeConnected />
      )}
      {emergencyCallDetailsStatus ===
        EmergencyCallDetailsStatus.CALLEE_RECONNECT && (
        <EmergencyCallDetailsCalleeReconnect />
      )}
      {emergencyCallDetailsStatus ===
        EmergencyCallDetailsStatus.CHANGE_NUMBER_VCO_2_LINE && (
        <EmergencyCallDetailsChangeNumberVco2Line />
      )}
      {emergencyCallDetailsStatus ===
        EmergencyCallDetailsStatus.NEW_VCO_2_LINE && (
        <EmergencyCallDetailsAddNumberVco2Line />
      )}
      {isOtherConnectionPoint &&
        emergencyCallDetailsStatus !==
          EmergencyCallDetailsStatus.CALLEE_CONNECTED &&
        !isAcceptingUserAndCurrentlyTeaming && (
          <>
            <Separator />
            <NewCallee onClick={handleHearingSpawnNewCall} />
            <EmergencyNewCall />
          </>
        )}
      <SignMailText />
    </>
  );
};

const EmergencyInfoContainer = styled('div')`
  margin-bottom: ${theme.spacing(8)};
`;
