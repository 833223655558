import { Typography, FormControlLabel } from '@mui/material';
import type { Dispatch, FC, RefObject } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import {
  deafPhoneNumberIsHiddenSelector,
  toggleDeafPhoneNumberIsHiddenState,
} from 'features/deaf-phone-number-hidden/store';
import { Hotkey } from 'config/enums';
import { CallDetailsButtonConnect } from 'features/call/call-details/components';
import {
  DialedNumbersHistoryButton,
  DialedNumbersHistoryList,
} from 'features/dialed-numbers-history/components';
import { PhoneNumberInput } from 'features/phone-number/components';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';
import { Label, Switch } from 'features/ui/elements';

export interface NewCallProps {
  refPhoneNumber: RefObject<HTMLInputElement>;
  isPhoneNumberValid?: boolean;
  onSetPhoneNumber: Dispatch<string>;
  dialedNumbersList: string[];
  isOpenHistory: boolean;
  onOpenHistory: () => void;
  onHearingSpawnNewCall: () => void;
  className?: string;
  callError?: string;
}

export const NewCall: FC<NewCallProps> = ({
  refPhoneNumber,
  isPhoneNumberValid,
  onSetPhoneNumber,
  dialedNumbersList,
  isOpenHistory,
  onOpenHistory,
  onHearingSpawnNewCall,
  className,
  callError,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { t: newCallTranslation } = useTranslation('translation', {
    keyPrefix: 'newCall',
  });
  const deafPhoneNumberIsHidden = useAppSelector(
    deafPhoneNumberIsHiddenSelector
  );

  return (
    <Container className={className}>
      <Head>
        <Title variant='textLargeMedium'>{newCallTranslation('title')}</Title>
        <DialedNumbersHistoryButton
          dialedNumbersList={dialedNumbersList}
          isOpenHistory={isOpenHistory}
          onOpenHistory={onOpenHistory}
        />
      </Head>
      {callError ? (
        <Typography variant='label' color={theme.palette.error.light}>
          {callError}
        </Typography>
      ) : (
        <Label text={newCallTranslation('label')} />
      )}
      <PhoneNumberInputStyle title='phoneNumber' refInput={refPhoneNumber} />
      <StyledFormControlLabel
        control={
          <Switch
            onChange={() => dispatch(toggleDeafPhoneNumberIsHiddenState())}
            checked={!deafPhoneNumberIsHidden}
          />
        }
        label={t('showCallerId')}
      />
      <CallDetailsButtonConnect
        disabled={!isPhoneNumberValid}
        onClick={onHearingSpawnNewCall}
        hotkey={Hotkey.AddCallee}
      />
      {isOpenHistory && (
        <DialedNumbersHistoryList
          dialedNumbersList={dialedNumbersList}
          onSetPhoneNumber={onSetPhoneNumber}
        />
      )}
    </Container>
  );
};

const Container = styled('div')`
  margin-top: ${theme.spacing(8)};
  border-radius: ${theme.spacing(4)};
  padding: ${theme.spacing(8)};
  background-color: ${theme.palette.grey[800]};
`;

const Head = styled('div')`
  color: ${theme.palette.common.white};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${theme.spacing(4)};
`;

const Title = styled(Typography)`
  color: ${theme.palette.common.white};
`;

const PhoneNumberInputStyle = styled(PhoneNumberInput)`
  margin-top: ${theme.spacing(3)};
  margin-bottom: ${theme.spacing(6)};
  padding: ${theme.spacing(4, 6)};
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  margin-bottom: ${theme.spacing(8)};
`;
