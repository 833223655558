import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { Gender, Language } from 'config/enums';
import {
  TeamingReason,
  TeamingStatus,
  TeamingType,
} from 'features/teaming/teaming-base/enums';
import type {
  AcceptingUser,
  RequestingUser,
  Teaming,
  TeamingOptionsPayload,
} from 'features/teaming/teaming-base/interfaces';

export const initialTeamingState: Teaming = {
  status: TeamingStatus.NONE,
  gender: Gender.U,
  language: Language.English,
  reason: TeamingReason.Emergency,
  roomUri: '',
  isCurrentlyOnTeamingConference: false,
  teamingType: TeamingType.RemoteRq,
  isRemote: true,
  station: '',
  requestingUser: {
    id: 0,
    name: '',
    sessionId: '',
  },
  acceptingUser: {
    id: 0,
    name: '',
    number: '',
    sessionId: '',
  },
};

export const teamingSlice = createSlice({
  name: 'teaming',
  initialState: initialTeamingState,
  reducers: {
    setTeamingStatus: (state, action: PayloadAction<TeamingStatus>) => {
      state.status = action.payload;
    },
    setTeamingOptions: (
      state,
      action: PayloadAction<TeamingOptionsPayload>
    ) => {
      state.reason = action.payload.reason;
      state.gender = action.payload.gender;
      if (action.payload.roomUri) {
        state.roomUri = action.payload.roomUri;
      }
      state.language = action.payload.language;
      state.teamingType = action.payload.teamingType;
      state.isRemote = action.payload.isRemote;
    },
    setTeamingReason: (state, action: PayloadAction<TeamingReason>) => {
      state.reason = action.payload;
    },
    setTeamingStation: (state, action: PayloadAction<string>) => {
      state.station = action.payload;
    },
    setTeamingRequestingUser: (
      state,
      action: PayloadAction<RequestingUser>
    ) => {
      state.requestingUser = {
        ...state.requestingUser,
        ...action.payload,
      };
    },
    setTeamingAcceptingUser: (
      state,
      action: PayloadAction<Partial<AcceptingUser>>
    ) => {
      state.acceptingUser = {
        ...state.acceptingUser,
        ...action.payload,
      };
    },
    setTeamingAnotherUserSessionId: (state, action: PayloadAction<string>) => {
      const user = state.requestingUser.id ? 'requestingUser' : 'acceptingUser';
      state[user].sessionId = action.payload;
    },
    // only want to reset the isCurrentlyOnTeamingConference property once the call is completed or for the VI who has left the teaming session
    resetTeamingFreeSwitchCallStillInProgress: (state) => {
      const isPrevious = state.isCurrentlyOnTeamingConference;
      return {
        ...initialTeamingState,
        isCurrentlyOnTeamingConference: isPrevious,
      };
    },
    setIsCurrentCallOnFreeSwitch: (state) => {
      state.isCurrentlyOnTeamingConference = true;
    },
    resetTeaming: () => initialTeamingState,
  },
});

export const {
  setTeamingStatus,
  setTeamingReason,
  setTeamingOptions,
  setTeamingRequestingUser,
  setTeamingAcceptingUser,
  resetTeaming,
  setTeamingStation,
  setTeamingAnotherUserSessionId,
  resetTeamingFreeSwitchCallStillInProgress,
  setIsCurrentCallOnFreeSwitch,
} = teamingSlice.actions;

export const teamingReducer = teamingSlice.reducer;
