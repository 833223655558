import { styled } from '@mui/material';
import { typography } from '@mui/system';
import type { FC } from 'react';
import { useEffect, useState } from 'react';

import theme from 'features/theme/theme';
import { useWhileComponentMounted } from 'features/utils/hook';
import { VrsChatMessage } from 'features/vrs-chat/components';
import { processIncomingMessage } from 'features/vrs-chat/helpers';
import { VrsChatEventBus } from 'features/vrs-chat/services';

interface VrsChatMessageRemoteProps {
  userName: string;
  isWaitingClearConfirm: boolean;
  onCloseConfirm: () => void;
}

export const VrsChatMessageRemote: FC<VrsChatMessageRemoteProps> = ({
  userName,
  isWaitingClearConfirm,
  onCloseConfirm,
}) => {
  const whileComponentMounted = useWhileComponentMounted();
  const [receiveMessage, setReceiveMessage] = useState<string>('');
  const handleReceiveMessage = (message: string) => {
    setReceiveMessage((prev) => processIncomingMessage(prev, message));
  };

  const clearMessage = () => {
    setReceiveMessage('');
  };

  useEffect(() => {
    VrsChatEventBus.$receiveMessage
      .pipe(whileComponentMounted())
      .subscribe(handleReceiveMessage);
    VrsChatEventBus.$clearMessage
      .pipe(whileComponentMounted())
      .subscribe(clearMessage);
    VrsChatEventBus.$closeDataChannel
      .pipe(whileComponentMounted())
      .subscribe(clearMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isWaitingClearConfirm && receiveMessage === '') {
      onCloseConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isWaitingClearConfirm, receiveMessage]);

  return (
    <>
      {receiveMessage && (
        <Container data-testid='vrs-chat-message-remote'>
          <VrsChatMessage userName={userName}>
            <MessageContent>{receiveMessage}</MessageContent>
          </VrsChatMessage>
        </Container>
      )}
    </>
  );
};

const MessageContent = styled('div')`
  word-break: break-word;
  white-space: pre-wrap;
  ${typography(theme.typography.body2)};
  color: ${theme.palette.common.white};
`;

const Container = styled('div')`
  border-radius: 12px 12px 12px 0;
  background-color: ${theme.palette.grey[800]};
`;
