import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import {
  AddCallee,
  AddHearingConnecting,
  CallDetailsButtonAddHearing,
} from 'features/call/call-details/components';
import { AddHearingStatus } from 'features/call/call-details/enums';
import {
  addHearingStatusSelector,
  setAddHearingStatus,
} from 'features/call/call-details/store';
import { useHearings } from 'features/call/call-hearing/hooks';
import {
  selectLastSecondaryHearing,
  primaryHearingStatusSelector,
} from 'features/call/call-hearing/store';
import { shouldCallControlsBeVisibleSelector } from 'features/spawn-call/store';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const CallDetailsAddHearing = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'addCallee',
  });
  const dispatch = useAppDispatch();

  const addHearingStatus = useAppSelector(addHearingStatusSelector);
  const lastSecondaryHearing = useAppSelector(selectLastSecondaryHearing);
  const primaryHearingStatus = useAppSelector(primaryHearingStatusSelector);
  const shouldCallControlsBeVisible = useAppSelector(
    shouldCallControlsBeVisibleSelector
  );

  const { handleRegularHearingCall, handleHearingDisconnect } =
    useCallHearingParticipantHandlers();

  const { addHearingIfNotExists } = useHearings();

  const handleAddHearingButton = () => {
    dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
  };

  const handleEnterPhoneNumber = async (phoneNumber: string) => {
    try {
      dispatch(setAddHearingStatus(AddHearingStatus.CONNECTING));
      await addHearingIfNotExists(phoneNumber);
      await handleRegularHearingCall(phoneNumber);
    } catch {
      dispatch(setAddHearingStatus(AddHearingStatus.ENTER_PHONE_NUMBER));
    }
  };

  const handleCancel = () => {
    dispatch(setAddHearingStatus(AddHearingStatus.BASE));
  };

  const handleCancelConnecting = async (id: string) => {
    if (!id) {
      return;
    }

    await handleHearingDisconnect(id);
    dispatch(setAddHearingStatus(AddHearingStatus.BASE));
  };

  useEffect(() => {
    if (
      addHearingStatus === AddHearingStatus.CONNECTING &&
      lastSecondaryHearing?.status === 'connected'
    ) {
      dispatch(setAddHearingStatus(AddHearingStatus.BASE));
    }
  }, [dispatch, addHearingStatus, lastSecondaryHearing?.status]);

  if (primaryHearingStatus !== 'connected') {
    return null;
  }

  if (!shouldCallControlsBeVisible) {
    return;
  }

  return (
    <Container>
      {addHearingStatus === AddHearingStatus.BASE && (
        <CallDetailsButtonAddHearing onClick={handleAddHearingButton} />
      )}
      {addHearingStatus === AddHearingStatus.ENTER_PHONE_NUMBER && (
        <Wrapper>
          <AddCallee
            onClick={handleEnterPhoneNumber}
            onCancel={handleCancel}
            title={t('addHearingCallee')}
          />
        </Wrapper>
      )}
      {addHearingStatus === AddHearingStatus.CONNECTING &&
        lastSecondaryHearing && (
          <Wrapper>
            <AddHearingConnecting
              onCancel={handleCancelConnecting}
              hearing={lastSecondaryHearing}
            />
          </Wrapper>
        )}
    </Container>
  );
};

const Container = styled('div')`
  margin-top: ${theme.spacing(6)};
`;

const Wrapper = styled('div')`
  margin-top: ${theme.spacing(2)};
`;
