import { useMemo } from 'react';

import { InfoCircleIcon, MedicalCrossIcon } from 'assets/svg';
import { useAppDispatch, useAppSelector } from 'features/app/hooks';
import { useCallHearingParticipantHandlers } from 'features/call/call-accepted/hooks';
import { updateCalleePhoneNumber } from 'features/call/call-base/store';
import {
  CallDetailsButtonCancel,
  CallDetailsButtonConnect,
  CallDetailsButtonDisconnect,
  CallDetailsButtonReconnect,
  CallDetailsParticipantIcon,
} from 'features/call/call-details/components';
import { primaryHearingSelector } from 'features/call/call-hearing/store';
import { EmergencyNewCall } from 'features/emergency/components';
import { EmergencyConnectionPoint } from 'features/emergency/enums';
import {
  addCalledConnectionPoints,
  emergencyConnectionPointSelector,
  isCalledToConnectionPointSelector,
  isEmergencyCalleeReconnectSelector,
  isOtherConnectionPointSelector,
  selectShouldCancelHearingBeDisabledForEmergencyCall,
} from 'features/emergency/store';
import { shouldCallControlsBeVisibleSelector } from 'features/spawn-call/store';
import { VoiceSessionStatus } from 'features/voice-session/enums';
import {
  isVoiceSessionLoadingSelector,
  voiceSessionStatusSelector,
} from 'features/voice-session/store';

export const useEmergencyCallDetailsCallee = () => {
  const dispatch = useAppDispatch();
  const { handleRegularHearingCall, handleHearingDisconnect } =
    useCallHearingParticipantHandlers();
  const hearing = useAppSelector(primaryHearingSelector);
  const emergencyConnectionPoint = useAppSelector(
    emergencyConnectionPointSelector
  );
  const voiceSessionStatus = useAppSelector(voiceSessionStatusSelector);
  const isVoiceSessionLoading = useAppSelector(isVoiceSessionLoadingSelector);
  const isEmergencyCalleeReconnect = useAppSelector(
    isEmergencyCalleeReconnectSelector
  );

  const isCalledToConnectionPoint = useAppSelector(
    isCalledToConnectionPointSelector
  );
  const isOtherConnectionPoint = useAppSelector(isOtherConnectionPointSelector);
  const handleStartCall = async () => {
    await dispatch(updateCalleePhoneNumber());
    await handleRegularHearingCall(hearing.phoneNumber);
    if (emergencyConnectionPoint) {
      dispatch(addCalledConnectionPoints(emergencyConnectionPoint));
    }
  };

  const shouldHearingCancelBeDisabled = useAppSelector(
    selectShouldCancelHearingBeDisabledForEmergencyCall()
  );

  const shouldCallControlsBeVisible = useAppSelector(
    shouldCallControlsBeVisibleSelector
  );

  const calleeButton = useMemo(() => {
    if (!shouldCallControlsBeVisible) {
      return null;
    }

    if (voiceSessionStatus === VoiceSessionStatus.CONNECTING) {
      return <CallDetailsButtonCancel loading />;
    }

    if (isCalledToConnectionPoint && isEmergencyCalleeReconnect) {
      return (
        <>
          <CallDetailsButtonReconnect onClick={handleStartCall} />
          <EmergencyNewCall />
        </>
      );
    }

    if (hearing.status === 'connecting') {
      return (
        <CallDetailsButtonCancel
          loading={isVoiceSessionLoading || !hearing.id}
          onClick={() => handleHearingDisconnect(hearing.id)}
          disabled={shouldHearingCancelBeDisabled}
        />
      );
    }

    if (hearing.status === 'connected') {
      return (
        <CallDetailsButtonDisconnect
          onClick={() => handleHearingDisconnect(hearing.id)}
        />
      );
    }

    if (!isCalledToConnectionPoint || hearing.status === 'disconnected') {
      return (
        <>
          <CallDetailsButtonConnect onClick={handleStartCall} />
          <EmergencyNewCall />
        </>
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hearing.id,
    hearing.status,
    hearing.phoneNumber,
    isEmergencyCalleeReconnect,
    handleRegularHearingCall,
    isVoiceSessionLoading,
    voiceSessionStatus,
  ]);

  const callee = useMemo(() => {
    const defaultMetadata = {
      children: calleeButton,
      testId: 'hearing-details-item',
    };

    if (emergencyConnectionPoint === EmergencyConnectionPoint.ECRC) {
      return {
        ...defaultMetadata,
        name: 'ECRC (Emergency Call Relay Center)',
        icon: (
          <InfoCircleIcon width={32} height={32} data-testid='info-circle' />
        ),
      };
    }

    if (emergencyConnectionPoint === EmergencyConnectionPoint.PSAP) {
      return {
        ...defaultMetadata,
        name: '911 Center',
        icon: (
          <MedicalCrossIcon
            width={32}
            height={32}
            title='Medical cross medium'
          />
        ),
      };
    }

    if (!isEmergencyCalleeReconnect && isOtherConnectionPoint) {
      return {
        ...defaultMetadata,
        ...hearing,
        icon: <CallDetailsParticipantIcon isDeafParticipant={false} />,
      };
    }
  }, [
    calleeButton,
    emergencyConnectionPoint,
    isEmergencyCalleeReconnect,
    isOtherConnectionPoint,
    hearing,
  ]);

  return { callee };
};
