import { httpClient } from 'common/services';
import type { SendAnalyticsRequest } from 'features/analytics/interfaces';
import { env } from 'features/environment/constants';

const sendLogsLocalDevelopment = import.meta.env.VITE_APP_LOCAL_LOGGING_ENABLED;

async function sendAnalytics(payload: SendAnalyticsRequest) {
  if (!env.isProductionBuild && !sendLogsLocalDevelopment) {
    return;
  }

  const route = '/analytics';
  return await httpClient.post(route, { ...payload }, { keepAlive: true });
}

export const AnalyticsDaoService = {
  sendAnalytics,
};
