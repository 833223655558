import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';

import { parsePhoneNumber } from 'features/call/call-base/helpers';
import type { HearingSelector } from 'features/call/call-hearing/helpers';
import {
  findHearingById,
  findHearingByPhoneNumber,
  findPrimaryHearing,
  findVCO2Line,
} from 'features/call/call-hearing/helpers';
import type {
  CallHearingState,
  HearingParticipant,
  HearingParticipantStatus,
  HearingParticipantSyncUpdate,
  HearingPhone,
} from 'features/call/call-hearing/interfaces';
import { HearingParticipantType } from 'features/call/call-hearing/interfaces';
import { adaptIncomingVco2Line } from 'features/call/call-hearing/store';
import type { CallDirection } from 'features/voice-session/interfaces';

export const initialPrimaryHearing: HearingParticipant = {
  id: '',
  name: '',
  phoneNumber: '',
  phoneExtension: '',
  location: '',
  status: 'disconnected',
  type: HearingParticipantType.PRIMARY,
  direction: 'outbound',
  isWavelloRegistered: false,
  isWavelloCallActive: false,
  isLoading: false,
  hasConnected: false,
};

export const initialTwoLineVco: HearingParticipant = {
  id: '',
  name: '',
  phoneNumber: '',
  phoneExtension: '',
  location: '',
  status: 'disconnected',
  type: HearingParticipantType.VCO2LINE,
  isLoading: false,
  hasConnected: false,
};

export const initialCallHearingState: CallHearingState = {
  hearings: [initialPrimaryHearing, initialTwoLineVco],
  isWSConnectionEstablished: false,
  callError: '',
};

export const callHearingSlice = createSlice({
  name: 'call-hearing',
  initialState: initialCallHearingState,
  reducers: {
    setReceivedPrimaryHearing: (
      state,
      action: PayloadAction<HearingParticipant>
    ) => {
      state.hearings[0] = action.payload;
    },
    setReceivedTwoLineVco: (state, action: PayloadAction<string>) => {
      state.hearings[1] = adaptIncomingVco2Line(action.payload);
    },
    setHearingStatus: (
      state,
      action: PayloadAction<
        HearingSelector & { status: HearingParticipantStatus }
      >
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.status = action.payload.status;
    },
    setHearingLoadingStateByPhoneNumber: (
      state,
      action: PayloadAction<{ phoneNumber: HearingPhone; isLoading: boolean }>
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.isLoading = action.payload.isLoading;
    },
    setHearingLoadingStateById: (
      state,
      action: PayloadAction<{ isLoading: boolean; participantId: string }>
    ) => {
      const hearing = findHearingById(state, action.payload.participantId);
      hearing.isLoading = action.payload.isLoading;
    },
    setHearingStatusWithId: (
      state,
      action: PayloadAction<
        HearingSelector & { status: HearingParticipantStatus; id: string }
      >
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.status = action.payload.status;
      hearing.id = action.payload.id;
    },
    setPrimaryHearingPhoneNumber: (
      state,
      action: PayloadAction<HearingPhone>
    ) => {
      const primaryHearing = findPrimaryHearing(state);
      primaryHearing.phoneNumber = parsePhoneNumber(action.payload);
    },
    setPrimaryHearingDirection: (
      state,
      action: PayloadAction<CallDirection>
    ) => {
      const primaryHearing = findPrimaryHearing(state);
      primaryHearing.direction = action.payload;
    },
    setHearingName: (
      state,
      action: PayloadAction<
        HearingSelector & { name: HearingParticipant['name'] }
      >
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.name = action.payload.name;
    },
    setHearingLocation: (
      state,
      action: PayloadAction<
        HearingSelector & { location: HearingParticipant['location'] }
      >
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.location = action.payload.location;
    },
    setPrimaryHearingIsWavelloRegistered: (
      state,
      action: PayloadAction<boolean>
    ) => {
      const primaryHearing = findPrimaryHearing(state);
      primaryHearing.isWavelloRegistered = action.payload;
    },
    setVco2LinePhoneNumber: (state, action: PayloadAction<HearingPhone>) => {
      const vco2Line = findVCO2Line(state);
      vco2Line.phoneNumber = parsePhoneNumber(action.payload);
    },
    addHearing: (state, action: PayloadAction<HearingParticipant>) => {
      state.hearings.push(action.payload);
    },
    resetCallHearing: () => initialCallHearingState,
    establishHearingWebSocketConnection: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isWSConnectionEstablished = action.payload;
    },
    setHearingAsDisconnected: (state, action: PayloadAction<string>) => {
      const hearing = state.hearings.find(
        ({ id }) => id === action.payload
      ) as HearingParticipant;
      if (hearing && hearing.status !== 'disconnected') {
        hearing.status = 'disconnected';
      }
      if (hearing?.pendingDisconnect) {
        hearing.pendingDisconnect = false;
      }
    },
    setCallError: (state, action: PayloadAction<string>) => {
      state.callError = action.payload;
    },
    clearCallError: (state) => {
      state.callError = '';
    },
    setPrimaryHearingIsWavelloActive: (
      state,
      action: PayloadAction<boolean>
    ) => {
      const primaryHearing = findPrimaryHearing(state);
      primaryHearing.isWavelloCallActive = action.payload;
    },
    setCallHearingId: (
      state,
      action: PayloadAction<{ id: string; phoneNumber: HearingPhone }>
    ) => {
      const hearing = findHearingByPhoneNumber(state, action.payload);
      hearing.id = action.payload.id;
    },
    pendingDisconnectById: (state, action: PayloadAction<{ id: string }>) => {
      const hearing = findHearingById(state, action.payload.id);
      if (hearing) {
        hearing.pendingDisconnect = true;
      }
    },
    hearingConnected: (
      state,
      action: PayloadAction<{ phoneNumber: HearingPhone }>
    ) => {
      // initial start of redux refactor which will be completed in a separate story
      const hearing = findHearingByPhoneNumber(state, action.payload);
      if (hearing) {
        hearing.hasConnected = true;
      }
    },
    updateHearingsOnSync: (
      state,
      action: PayloadAction<HearingParticipantSyncUpdate[]>
    ) => {
      state.hearings = action.payload.map((hearing) => ({
        ...hearing,
        isLoading: false,
      }));
    },
  },
});

export const {
  resetCallHearing,
  setReceivedPrimaryHearing,
  setReceivedTwoLineVco,
  setHearingStatus,
  setHearingStatusWithId,
  setPrimaryHearingPhoneNumber,
  setHearingName,
  setHearingLocation,
  setPrimaryHearingIsWavelloRegistered,
  setVco2LinePhoneNumber,
  addHearing,
  setHearingAsDisconnected,
  establishHearingWebSocketConnection,
  setCallError,
  clearCallError,
  setPrimaryHearingIsWavelloActive,
  setPrimaryHearingDirection,
  setCallHearingId,
  setHearingLoadingStateByPhoneNumber,
  setHearingLoadingStateById,
  pendingDisconnectById,
  hearingConnected,
  updateHearingsOnSync,
} = callHearingSlice.actions;

export const callHearingReducer = callHearingSlice.reducer;
