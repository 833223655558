import { useEffect, useRef } from 'react';

export const useAutoResizableTextarea = () => {
  const ref = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    const textarea = ref.current;
    if (!textarea) {
      return;
    }

    textarea.style.transition = 'height 0.1s ease';
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, []);

  return ref;
};
