import { Typography, Menu, MenuItem, styled, Box } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import theme from 'features/theme/theme';

interface CallDetailsAccordianLanguageMenuProps {
  anchorEl?: HTMLElement | null;
  open: boolean;
  onClose?: () => void;
  selectedLanguage?: string;
  onLanguageSelect: (lang: string) => void;
}

export const CallDetailsAccordianLanguageMenu = ({
  anchorEl,
  open,
  onClose,
  selectedLanguage: initialSelectedLanguage,
  onLanguageSelect,
}: CallDetailsAccordianLanguageMenuProps) => {
  const { t } = useTranslation('translation');
  const [selectedLanguage, setSelectedLanguage] = useState(
    initialSelectedLanguage
  );

  const handleLanguageClick = (lang: string) => {
    setSelectedLanguage(lang);
    onLanguageSelect(lang);
  };
  return (
    <StyledMenu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      <StyledMenuItem onClick={() => handleLanguageClick('English')}>
        <IconWrapper>
          {selectedLanguage === 'English' && (
            <StyledCheckIcon data-testid='check-icon' />
          )}
        </IconWrapper>
        <Text selected={selectedLanguage === 'English'}>{t('english')}</Text>
      </StyledMenuItem>

      <StyledMenuItem onClick={() => handleLanguageClick('Spanish')}>
        <IconWrapper>
          {selectedLanguage === 'Spanish' && (
            <StyledCheckIcon data-testid='check-icon' />
          )}
        </IconWrapper>
        <Text selected={selectedLanguage === 'Spanish'}>{t('spanish')}</Text>
      </StyledMenuItem>
    </StyledMenu>
  );
};

const StyledMenu = styled(Menu)(() => ({
  '& .MuiPaper-root': {
    color: theme.palette.common.white,
    minWidth: '200px',
    boxShadow: `0px 5px 15px ${theme.palette.grey[900]}`,
    borderRadius: '8px',
  },
  '& .MuiMenu-list': {
    backgroundColor: theme.palette.common.black,
    border: `1px solid ${theme.palette.grey[900]}`,
    borderRadius: '8px',
  },
  '& .MuiMenuItem-root': {
    padding: '10px 16px',
    '&:hover': {
      backgroundColor: theme.palette.grey[800],
      borderRadius: theme.spacing(4),
    },
  },
}));

const StyledMenuItem = styled(MenuItem)`
  display: flex;
  align-items: center;
  padding: ${theme.spacing(4)} ${theme.spacing(8)};
  width: 230px;
`;
const Text = styled(Typography)<{ selected?: boolean }>`
  font-weight: 500;
  font-size: 16px;
  color: ${(props) =>
    props.selected ? theme.palette.primary[500] : theme.palette.common.white};
`;

const StyledCheckIcon = styled(CheckIcon)`
  color: ${theme.palette.primary[500]};
  font-size: 20px;
  margin-right: ${theme.spacing(8)};
`;

const IconWrapper = styled(Box)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
