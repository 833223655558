import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'features/app/hooks';
import { selectIsCallCanBeClosed } from 'features/call/call-finish/store';
import { selectIsAnyHearingPendingDisconnect } from 'features/call/call-hearing/store';
import type { ButtonProps } from 'features/ui/elements';
import { Button } from 'features/ui/elements';

export const CallDetailsButtonConnect: FC<
  Pick<ButtonProps, 'onClick' | 'loading' | 'disabled' | 'className' | 'hotkey'>
> = ({ loading, onClick, disabled, className, hotkey }) => {
  const { t } = useTranslation();

  const isCallCanBeClosed = useAppSelector(selectIsCallCanBeClosed);
  const isAnyHearingPendingDisconnect = useAppSelector(
    selectIsAnyHearingPendingDisconnect
  );
  const isDisabled =
    isCallCanBeClosed || isAnyHearingPendingDisconnect || disabled;

  return (
    <Button
      fullWidth
      data-testid='call-details-connect-button'
      size='small'
      color='primary'
      loading={loading}
      onClick={onClick}
      disabled={isDisabled}
      className={className}
      hotkey={hotkey}
    >
      {t('connect')}
    </Button>
  );
};
