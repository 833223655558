import type { ThunkAction } from '@reduxjs/toolkit';
import type { RTCSession } from 'jssip/lib/RTCSession';

import { declinedPushedCall, newHearingConnection } from './callerSessionSlice';

import { CallerSessionStatus } from 'features/caller-session/enums/';
import {
  callerSessionAnalyticsInfo,
  getDefaultAnalyticsInfo,
  isUnregisteredCaller,
} from 'features/caller-session/helpers';
import type { AppDispatch, RootState } from 'features/app/store/store';
import { parsePhoneNumberWithRemovePlus } from 'features/call/call-base/helpers';
import { isConferenceCall } from 'features/call/call-base/store/selectors';
import { isEmergencyCallTypeSelector } from 'features/emergency/store';
import { isVriModeSelector } from 'features/multi-mode/store';
import { isWavelloSession } from 'features/wavello/helpers';
import { extractPhoneNumberFromSIP } from 'common/helpers';
import { isEmergencyCallSelector } from 'features/call/vrs-call/store/vrsCallSelectors';

interface SessionProps {
  isIncoming: boolean;
  remoteIdentifier: string;
  isAlreadyConnected: boolean;
  isAnyWebRtcConnected: boolean;
  isReadyForTeaming: boolean;
  validUnregisteredCaller: boolean;
  isWavelloCall: boolean;
  isWavelloPossible: boolean;
}

interface CallValidation {
  doesUriIncludeCaller: boolean;
  doesContactIncludeCaller: boolean;
  doesCallInfoIncludeCaller: boolean;
}

const validateCallIdentifiers = (
  caller: string,
  endpointContact: string,
  remoteIdentifier: string,
  callInfo?: string
): CallValidation => {
  const normalizedCaller = parsePhoneNumberWithRemovePlus(caller);
  const normalizedContact = parsePhoneNumberWithRemovePlus(
    extractPhoneNumberFromSIP(endpointContact)
  );
  const normalizedRemote = parsePhoneNumberWithRemovePlus(
    extractPhoneNumberFromSIP(remoteIdentifier)
  );

  return {
    doesUriIncludeCaller: normalizedRemote === normalizedCaller,
    doesContactIncludeCaller: normalizedContact === normalizedCaller,
    doesCallInfoIncludeCaller: callInfo?.includes(caller) ?? false,
  };
};

const getSessionProps = (
  session: RTCSession,
  state: RootState,
  webRtcRemoteIdentities: string[]
): SessionProps => {
  const remoteIdentifier = session.remote_identity.uri.toString();

  return {
    isIncoming: session.direction === 'incoming',
    remoteIdentifier,
    isAlreadyConnected: webRtcRemoteIdentities.includes(remoteIdentifier),
    isAnyWebRtcConnected: webRtcRemoteIdentities.length > 0,
    isReadyForTeaming: remoteIdentifier.includes(state.teaming.roomUri),
    validUnregisteredCaller: isUnregisteredCaller(remoteIdentifier),
    isWavelloCall: isWavelloSession(session),
    isWavelloPossible:
      state.callHearing.hearings[0].isWavelloRegistered ?? false,
  };
};

export const checkShouldAllowNewRtcSession =
  (
    session: RTCSession,
    rtcCallInfo: {
      callInfo: string | undefined;
      endpointMacAddress: string | undefined;
      contact: string;
    }
  ): ThunkAction<
    {
      shouldAllow: boolean;
      reasonMessage: string;
      analyticsInfo: Record<string, any>;
    },
    RootState,
    unknown,
    any
  > =>
  (dispatch, getState) => {
    const state = getState();
    const {
      status,
      caller,
      endpointMacAddress: macAddressFromRelay,
      webRtcRemoteIdentities,
      relayCallId,
    } = state.callerSession;

    const defaultAnalytics = getDefaultAnalyticsInfo(
      session,
      rtcCallInfo,
      state
    );
    const defaultResult = {
      shouldAllow: false,
      reasonMessage: 'New RTCSessions are not allowed by default.',
      analyticsInfo: defaultAnalytics,
    };

    // Early validation
    if (
      status !== CallerSessionStatus.InCallerSession ||
      !relayCallId ||
      relayCallId === '0'
    ) {
      return {
        ...defaultResult,
        reasonMessage: 'Invalid session state or no active relay call',
      };
    }

    if (isConferenceCall(state)) {
      return {
        ...defaultResult,
        reasonMessage:
          'Conference calls are not allowed to have WebRTC connections.',
      };
    }

    const sessionProps = getSessionProps(
      session,
      state,
      webRtcRemoteIdentities
    );
    const validation = validateCallIdentifiers(
      caller,
      rtcCallInfo.contact?.toString() ?? '',
      sessionProps.remoteIdentifier,
      rtcCallInfo.callInfo
    );

    const isVrsMode = !isVriModeSelector(state);
    const isEmergencyCall = isEmergencyCallTypeSelector(state);
    const callDeafPhoneNumber = state.callDeaf.phoneNumber;

    const vrsScenarios = [
      {
        condition:
          sessionProps.isIncoming &&
          (validation.doesContactIncludeCaller ||
            validation.doesUriIncludeCaller) &&
          !sessionProps.isAnyWebRtcConnected,
        message: 'Accepting call from the already-known caller',
      },
      {
        condition:
          sessionProps.isReadyForTeaming &&
          sessionProps.remoteIdentifier.includes('SVRS-RT'),
        message: 'Accepting connection for teaming',
      },
      {
        condition:
          !sessionProps.isIncoming &&
          sessionProps.remoteIdentifier.includes(callDeafPhoneNumber) &&
          !sessionProps.isAlreadyConnected,
        message: 'Allowing call to the already-known deaf person',
      },
      {
        condition:
          !sessionProps.isIncoming &&
          sessionProps.isWavelloCall &&
          sessionProps.isWavelloPossible,
        message: 'Allowing connection to Wavello',
      },
      {
        condition:
          isEmergencyCall &&
          !sessionProps.isAnyWebRtcConnected &&
          sessionProps.isIncoming &&
          sessionProps.validUnregisteredCaller,
        message:
          'Allowing incoming connection to emergency call to unregistered endpoint',
      },
    ];

    const vriScenarios = [
      {
        condition:
          sessionProps.isIncoming &&
          sessionProps.validUnregisteredCaller &&
          !sessionProps.isAnyWebRtcConnected &&
          validation.doesCallInfoIncludeCaller,
        message:
          'Allowing incoming VRI connection to unregistered endpoint with expected caller',
      },
      {
        condition:
          sessionProps.isIncoming &&
          rtcCallInfo.endpointMacAddress?.includes(macAddressFromRelay),
        message:
          'Allowing incoming VRI connection to unregistered endpoint with matching MAC address',
      },
    ];

    const scenarios = isVrsMode ? vrsScenarios : vriScenarios;
    const matchedScenario = scenarios.find((scenario) => scenario.condition);

    return {
      shouldAllow: !!matchedScenario,
      reasonMessage: matchedScenario?.message ?? defaultResult.reasonMessage,
      analyticsInfo: {
        ...defaultAnalytics,
        isVriMode: !isVrsMode,
        isEmergencyCall,
        doesCallInfoIncludeCaller: validation.doesCallInfoIncludeCaller,
      },
    };
  };

interface ShouldAllowResult {
  shouldAllow: boolean;
  reasonMessage: string;
}

/**
 * Synchronous thunk to check if a new hearing connection should be allowed.
 * If it should be allowed, this updates the state, and it won't be allowed again until it's marked as disconnected.
 * @param phoneNumber - The phone number to check.
 * @returns A thunk action that returns the result.
 */
export const checkShouldAllowNewHearingConnection =
  (
    phoneNumber: string
  ): ThunkAction<ShouldAllowResult, RootState, unknown, any> =>
  (dispatch, getState) => {
    dispatch({ type: 'callerSession/checkShouldAllowNewHearingConnection' });
    const state = getState();
    const normalizedNumber = parsePhoneNumberWithRemovePlus(phoneNumber);
    const status = state.callerSession.status;
    const switchboardConnections = state.callerSession.switchboardConnections;

    const defaultResult: ShouldAllowResult = {
      shouldAllow: true,
      reasonMessage: 'New hearing connections are allowed by default.',
    };

    if (status !== CallerSessionStatus.InCallerSession) {
      return {
        shouldAllow: false,
        reasonMessage: `Absolutely no calls are allowed outside of caller sessions. Status: ${status}`,
      };
    }

    const isNumberAlreadyInProgress =
      switchboardConnections.includes(normalizedNumber);

    dispatch(newHearingConnection(normalizedNumber));
    dispatch(
      callerSessionAnalyticsInfo(
        `New hearing connection being considered for ${normalizedNumber}`
      )
    );

    const scenarios = [
      {
        condition: isNumberAlreadyInProgress,
        message: 'Placing call to already in progress hearing is disallowed',
      },
    ];

    const matchedScenario = scenarios.find((scenario) => scenario.condition);
    const result: ShouldAllowResult = matchedScenario
      ? { shouldAllow: false, reasonMessage: matchedScenario.message }
      : defaultResult;

    return result;
  };

export const declinePushedCallIfNotEmergency =
  () => (dispatch: AppDispatch, getState: () => RootState) => {
    const state = getState();
    const isEmergencyCall = isEmergencyCallSelector(state);

    if (!isEmergencyCall) {
      // we cannot decline calls if its an emergency call due to the abandoned call flow.
      dispatch(declinedPushedCall());
    } else {
      console.warn('Cannot decline pushed call: Emergency call in progress.');
    }
  };
