import type { FC } from 'react';

import { Hotkey } from 'config/enums';
import { useAppSelector } from 'features/app/hooks';
import { useCallDeafParticipantHandlers } from 'features/call/call-accepted/hooks';
import { CallDetailsButtonDisconnect } from 'features/call/call-details/components';
import { isTeamingInProgressSelector } from 'features/teaming/teaming-base/store';

interface CallDetailsButtonDeafDisconnectProps {
  isLoading?: boolean;
}

export const CallDetailsButtonDeafDisconnect: FC<
  CallDetailsButtonDeafDisconnectProps
> = ({ isLoading }) => {
  const { handleDeafDisconnect } = useCallDeafParticipantHandlers();

  const isTeamingInProgress = useAppSelector(isTeamingInProgressSelector);

  if (isTeamingInProgress) {
    return null;
  }

  return (
    <CallDetailsButtonDisconnect
      loading={isLoading}
      disabled={isTeamingInProgress}
      onClick={handleDeafDisconnect}
      hotkey={Hotkey.CallerConnectionStateChange}
    />
  );
};
