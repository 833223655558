import { useCallback, useEffect, useRef } from 'react';

import { SignMailRecordingStatusWidget } from 'features/signmail/components';
import { maxVideoRecordingDuration } from 'features/signmail/constants';
import { useSignMailRecording } from 'features/signmail/hooks';
import { SignMailEventBus } from 'features/signmail/services';
import styled from 'features/theme/styled';
import theme from 'features/theme/theme';

export const SignMailRecording = () => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const { start } = useSignMailRecording();
  const hasStartedRef = useRef(false);

  const clearRecordingTimer = useCallback(() => {
    if (timerRef.current) {
      console.log('DEBUG: clearing timeout from SignMailRecording');
      clearTimeout(timerRef.current);
    }
  }, []);

  const startRecording = useCallback(async () => {
    if (!videoRef.current || hasStartedRef.current) {
      return;
    }
    hasStartedRef.current = true;
    await start(videoRef);

    timerRef.current = setTimeout(() => {
      SignMailEventBus.recordingFinished$.next();
    }, maxVideoRecordingDuration);
  }, [start]);

  useEffect(() => {
    startRecording();
    return clearRecordingTimer;
  }, [startRecording, clearRecordingTimer]);

  return (
    <Container data-testid='signmail-recording'>
      <SignMailRecordingStatusWidget />
      <StyledVideo ref={videoRef} autoPlay></StyledVideo>
    </Container>
  );
};

const Container = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: ${theme.palette.grey[900]};
`;

const StyledVideo = styled('video')`
  width: 100%;
  height: 100%;
  transform: rotateY(180deg);
`;
